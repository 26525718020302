import React from 'react';
import "./App.css";
import { usePrivy } from "@privy-io/react-auth";

function App() {
  const { ready, authenticated, user, login, logout } = usePrivy();

  // Wait until the Privy client is ready before taking any actions
  if (!ready) {
    return null;
  }

  return (
    <div className="App">
      <header className="header responsive-wrapper">
        
      {!authenticated && (
          <nav className="auth-links">
            
            <button onClick={login}  class="learn-more auth-link">
    <span class="circle" aria-hidden="true">
      <span class="icon arrow"></span>
    </span>
    <span class="button-text">Sign In</span>
  </button>
  
          </nav>
          
        )}
        
        <div className="title-wrapper">
   
          <h1 className="site-title">Blockchain Law</h1>

          <p className="site-subtitle">An International Journal
          <br></br>   <br></br>   
          
          <div class="icons centered">
       
        <i class="fa-brands fa-twitter"></i>
        <i class="fa-brands fa-linkedin"></i>
        <i class="fa-brands fa-youtube"></i>
      </div>
          </p>
       
     
        {ready && !authenticated && (
          <div className="centered-container">
  <div className="button-wrapper">

    <button onClick={login} className="site-button">
       Submit Manuscript
    </button>
  </div>
</div>

            
         
          )}
        </div>
        </header>
       
      {ready && authenticated && (
        <div className="user-info">
          <textarea
            readOnly
            value={JSON.stringify(user, null, 2)}
            style={{   borderRadius: "6px" }}
          />  
          <br />
          <div className="button-wrapper">
          <button onClick={logout} className="site-button">
            Log Out
          </button>
          </div>
          </div>
     
      )}

    </div>

  );
}

export default App;
